.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.w-100 {
  width: 100%;
}
.d-block {
  display: block;
}
.mx-auto {
  margin: 0 auto;
}
.btn-checkin {
  position: absolute!important;
  width: 100%;
}
.ant-table-wrapper {
  overflow-x: scroll;
}
.ant-form-item-explain {
  margin-top: 7px!important;
  margin-bottom: 7px!important;
}
.bg-cover-title {
  background: #104F9E;
}
.checkout {
  background-color: #026063;
}
.ant-layout-sider-children {
  background-color: #ffffff;
}
.ant-image-img,
.img-fluid {
  max-width: 100%;
  height: auto;
}
@font-face {
  font-family: 'Prompt';
  font-weight: normal;
  src: url('/fonts/Prompt/Prompt-Regular.ttf');
}
@font-face {
  font-family: 'Prompt';
  font-weight: lighter;
  src: url('/fonts/Prompt/Prompt-Light.ttf');
}
@font-face {
  font-family: 'Prompt';
  font-weight: bold;
  src: url('/fonts/Prompt/Prompt-Medium.ttf');
}
.ant-picker-header {
  color: black !important;
}
